import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {debounce} from "lodash";
import MindsetsJsSDK from "mindsets-js-sdk";

import {SetDashboardDataAction, SetDashboardFiltersAction} from "../../../../actions/performance_actions";
import Filters from "../../Filters";
import {default_filters} from "../../../../reducers/performance_reducers";
import Graph from "./Graph";
import {getStateBasedTitle} from "../TemplateTypeA/utils";

export default function ({printable}) {
    const FILTER_1 = 'filter-1';
    const FILTER_2 = 'filter-2';

    const dispatch = useDispatch();
    const params = useParams();

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);
    const dashboard_filters = useSelector(state => {
        if (Object.keys(state.dashboard_filters).length === 0) {
            return {
                [FILTER_1]: JSON.parse(JSON.stringify(default_filters)),
                [FILTER_2]: JSON.parse(JSON.stringify(default_filters))
            }
        }

        return state.dashboard_filters;
    });

    const debounceLoadData1 = useCallback(debounce((filters) => {
        MindsetsJsSDK().API.get(`dashboards/${params.dashboard_id}/skills`, filters).then((skill_data) => {
            dispatch(SetDashboardDataAction({skill_data}));
        });
    }, 1000), []);

    const debounceLoadData2 = useCallback(debounce((filters) => {
        MindsetsJsSDK().API.get(`dashboards/${params.dashboard_id}/skills`, filters).then((skill_data_2) => {
            dispatch(SetDashboardDataAction({skill_data_2}));
        });
    }, 1000), []);

    useEffect(() => {
        Promise.resolve().then(() => {
            dispatch(SetDashboardFiltersAction({
                [FILTER_1]: JSON.parse(JSON.stringify(default_filters)),
                [FILTER_2]: JSON.parse(JSON.stringify(default_filters)),
            }));
        }).then(() => {
            debounceLoadData1(dashboard_filters[FILTER_1]);
            debounceLoadData2(dashboard_filters[FILTER_2]);
        });
    }, []);

    useEffect(() => {
        if (printable) {
            return;
        }

        debounceLoadData1(dashboard_filters[FILTER_1]);
    }, [
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].impact_study : '',
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].subject : '',
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].class_grade.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].school_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].challenge_code.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].teacher_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].achievement_level.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].practice_skill_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].student_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].class_date : ''
    ]);

    useEffect(() => {
        if (printable) {
            return;
        }

        debounceLoadData2(dashboard_filters[FILTER_2]);
    }, [
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].impact_study : '',
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].subject : '',
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].class_grade.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].school_id.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].challenge_code.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].teacher_id.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].achievement_level.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].practice_skill_id.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].student_id.length : 0,
        dashboard_filters[FILTER_2] ? dashboard_filters[FILTER_2].class_date : ''
    ]);

    if (printable && !dashboard_data.skill_data) {
        return (
            <div>
                {`Missing data. Go back to the Insights Dashboard and start again.`}
            </div>
        );
    }

    return (
        <div className="flex-row flex-gap-10 flex-wrap mb-5">
            <div className='flex-1'>
                <Filters id={FILTER_1} key={FILTER_1}/>
                <Graph
                    printable={printable}
                    id={'g1'}
                    title={getStateBasedTitle(dashboard).skill}
                    skill_data={dashboard_data.skill_data}
                />
            </div>
            <div className='flex-1'>
                <Filters id={FILTER_2} key={FILTER_2}/>
                <Graph
                    printable={printable}
                    id={'g2'}
                    title={getStateBasedTitle(dashboard).skill}
                    skill_data={dashboard_data.skill_data_2}
                />
            </div>
        </div>
    );
}